@use "../global/functions";
@use "../global/variaveis";

.owl-carousel {
    &#cliente {
        margin-bottom: 2em;
        
        
        & .owl-nav {
            display: none;
        }
        & .owl-dots {
            position: absolute;
            left: 0;
            bottom: -6em;
            z-index: 1;
        }
    }
    .owl-stage-outer {
        overflow: visible !important;
    }
    & .owl-dots{
        display: block;
        width: 100%;
        font-size: 12px;
        margin-top: 1em;
        & .owl-dot{
            background-color: transparent;
            border:1px solid #fc0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            outline: none;
            margin-right: .5em;
            &.active{
                background-color: #fc0;
            }
        }
    }
}
