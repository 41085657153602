@use "functions";

/* recuros do layout ===================== */
.listStyleNone {
	padding: 0;
	margin: 0;
	list-style: none;
}
.textNone {
	text-indent: -9999px  !important;
	overflow: hidden  !important;
	outline: none  !important;
}
.aparence {
	@include functions.aparence( none );
}

.backSpec {
	@include functions.backgroundSizer( cover );
	background-position-x: center;
	background-position-y: top;
	background-repeat: no-repeat;
}

// figure defaults
.imgPadrao {
		display: block;
		width: 100%;
		height: auto !important;
		border: none;
}
.imgSetup{
    display: block;
    width: 100%;
    margin: 0;
    & img {
        @extend .imgPadrao;
    }
}


.bgBlack {
	background-color: #000;
	position: relative;
	z-index: 100;
	&.firstBOX {
		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: -99.99%;
			background-color: #000;
			@media screen and (max-width:992px) {
				display: none;
			}
		}
		.sidebarFixed {
			position: fixed;
			top: 8vw;
			left: 6vw;
			width: 30vw;
			@media screen and (max-width:1170px) {
				top: 5vw;
				.titleSession.fz-36 {
					font-size: 2.5vw;
				}
				h2 {
					font-size: 1.67vw;
				}
			}
			@media screen and (max-width:992px) {
				position: relative;
				top: 0;
				margin-top: 10vw;
				left: 0;
				width: 100%;
				.logobrand {
					margin: 0 auto;
				}
				.titleSession.fz-36 {
					font-size: 6vw;
					text-align: center;
				}
				.social {
					text-align: center;
					justify-content: center !important;
					li {
						margin: 0 1vw;
					}
				}
				h2 {
					font-size: 3vw;
					text-align: center;
				}
			}
			@media screen and (max-width:740px) {
				.titleSession.fz-36 {
					font-size: 7vw;
				}
				h2 {
					font-size: 3.9vw!important;
				}
			}
		}
	}
}
.bgPalco {
	position: relative;
	z-index: 10;
}