// definição translateX
@mixin translateX( $translateX ) {
    -moz-transform:     translateX($translateX);
    -webkit-transform:  translateX($translateX);
    -o-transform:       translateX($translateX);
    -ms-transform:      translateX($translateX);
    transform:          translateX($translateX);
}
// definição translateY
@mixin translateY( $translateY ) {
    -moz-transform:     translateY($translateY);
    -webkit-transform:  translateY($translateY);
    -o-transform:       translateY($translateY);
    -ms-transform:      translateY($translateY);
    transform:          translateY($translateY);
}
// definicao de tanslateNosDoisEixos 
@mixin translateXY( $translateX, $translateY ) {
    -moz-transform:     translateX($translateX) translateY($translateY);
    -webkit-transform:  translateX($translateX) translateY($translateY);
    -o-transform:       translateX($translateX) translateY($translateY);
    -ms-transform:      translateX($translateX) translateY($translateY);
    transform:          translateX($translateX) translateY($translateY);
}