@use "../global/variaveis";
@use "../layout/sessions";
@use '../global/functions';
@use '../global/constantes';

@use "../lib/transformacoes/scale";
@use "../lib/transicoes/transitions";
@use "../lib/efeitos/box-shadows";
@use "../lib/efeitos/gradientes";
@use "../lib/transformacoes/translate";
// @use "../componentes/shadows";


// map fontes
@use "../lib/mapeamento/fonts";
@use "sass:map";

@use "sass:math";
@use "sass:meta";

.sidebarFixed {
    
}

.logobrand {
    // margin-top: 10vh;
    max-width: 200px;
    border-radius: 50%;
    overflow: hidden;
}

.social {
    margin-top: 1em;
    @extend .listStyleNone;
    @include functions.displayFlex( 
        true, row, 0, center );
    & a {
        color: #fff;
        display: block;
    }
    & li {
        margin: 0px .67em;
    }
}

.listLink {
    @extend .listStyleNone;
    li, .itemListLink {
        margin-top: 1em;
        a {
            color: #fff;
            &:hover {
                background-color: #fc0;
            }
        }
        &:nth-child(8n + 1){
            a {background-color: #5b137f; color:#fc0; }
        }
        &:nth-child(8n + 2){
            a {background-color: #fc0082;}
        }
        &:nth-child(8n + 3){
            a {background-color: #ff6a00;}
        }
        &:nth-child(8n + 4){
            a {background-color: #5C08E7;}
        }
        &:nth-child(8n + 5){
            a {background-color: #006dfc;}
        }
        
        
        &:nth-child(8n + 6){
            a {background-color: #2FB554;}
        }
        &:nth-child(8n + 7){
            a {background-color: transparent; border:1px solid #fc0; color: #fc0;}
        }
        &:nth-child(8n + 8){
            a {background-color: #254bd3;}
        }
    }
    .itemListNavSuperior {
        margin-top: 1em;
        a {
            color: #fff;
            background-color: transparent; 
            border:1px solid #fc0; 
            color: #fc0;
            transition: all .3s ease-in-out;
            &:hover {
                color: #5b137f;
                background-color: #fc0;
            }
        }
        
    }
    & a {
        display: block;
        width: 100%;
        padding: 1em 0;
        background-color: #fff;
        color: #222;
        text-align: center;
        text-decoration: none;
        font-weight: variaveis.$bold;
    }
}

.listCliente {
    a {
        color: #fc0;
        text-decoration: underline;
    }
    .coverCliente {
        border-radius: 17px;
        overflow: hidden;
        margin: 0 0 .67em;
        a {
            padding: 0;
        }
    }
    #clientes {
        &:hover {
            cursor: grab;
        }
    }
}

.videoPlay {
    display: block;
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;
    .oVideo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;
        height: 100%;
        background-color: #000;
        mix-blend-mode: multiply;
        opacity: .67;
    }
    .player {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 30;
        color: #fff;
    }
    .textBox {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 30;
        padding: 1rem 1rem 2rem;

    }
}

.banner {
    background-color: #fc0;
    color: #5b137f;
    padding: 10px 20px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    p {
        font-size: 18px;
        margin: 0;
    }
}

.coverVideoBox {
    overflow: hidden;
    a {
       .oVideo {
            width: 126%;
            transform: translateX(-7em);
            @media screen and (max-width:740px) {
                width: 100%;
                transform: translateX(0);
            }
       } 
    }
}